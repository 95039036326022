import intl from "react-intl-universal";
import { IEvent } from "./event";
import { User } from "./model";

type SectionType = "events" | "hero";
type CategoryType =
  | "my_recommendations"
  | "my_favorites"
  | "culture"
  | "nature"
  | "gastro"
  | "action"
  | "relax"
  | "vacation_tips"
  | "christmas_market";

export interface ISection {
  type: SectionType;
  category: CategoryType;
  events: IEvent[];
}

export class Section {
  static getCategoryDisplayName(category: CategoryType, user?: User) {
    switch (category) {
      case "my_recommendations":
        return intl.get("category.recommendations");
      case "my_favorites":
        return intl.get("category.favorites");
      case "culture":
        return intl.get("category.culture");
      case "gastro":
        return intl.get("category.gastro");
      case "nature":
        return intl.get("category.nature");
      case "action":
        return intl.get("category.action");
      case "relax":
        return intl.get("category.relax");
      case "vacation_tips":
        return intl.get("category.vacation_tips");
      case "christmas_market":
        return intl.get("category.christmas_market");
      default:
        return intl.get("category.other");
    }
  }
}

export type CategorySortType = "relevance" | "distance";
